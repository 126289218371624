import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./chat.css";
import WebApp from "@twa-dev/sdk";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import enGB from 'date-fns/locale/en-GB'; 
registerLocale('en-GB', enGB);
import Checkbox from '@mui/material/Checkbox'; 

const Chats = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { chatName = "Unnamed Chat", chatId } = location.state || {};
  const [tasks, setTasks] = useState([]);
  const [user, setUser] = useState({});
  const [isTaskInputVisible, setIsTaskInputVisible] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [deadline, setDeadline] = useState("");
  const [selectedExecutors, setSelectedExecutors] = useState([]);
  const [selectedExecutorIds, setSelectedExecutorIds] = useState([]);
  const [isImportant, setIsImportant] = useState(false);
  const [showAllTasks, setShowAllTasks] = useState(false);
  const [showCompletedTasks, setShowCompletedTasks] = useState(false); // State to toggle completed tasks visibility
  const taskInputRef = useRef(null);
  const taskFormRef = useRef(null);
  const [chatAvatar, setChatAvatar] = useState(null);
  const [chatMembers, setChatMembers] = useState([]);
  const [executorAvatars, setExecutorAvatars] = useState({});
  const apiUrl = process.env.NODE_ENV === 'production' 
    ? 'https://tasks.ledokol.it/api' 
    : 'http://localhost:5000';

  useEffect(() => {
    const tg = WebApp;
    tg.ready();

    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/'); 
    });

    const fetchChatAvatar = async () => {
      try {
        const response = await fetch(`${apiUrl}/get-chat-avatar?chatId=${chatId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setChatAvatar(data.chatAvatar || null);
        } else {
          console.error('Failed to fetch chat avatar');
        }
      } catch (error) {
        console.error('Error fetching chat avatar:', error);
      }
    };

    const fetchChatMembers = async () => {
      try {
        const response = await fetch(`${apiUrl}/get-chat-members?chatId=${chatId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setChatMembers(data.members || []);  
        } else {
          console.error('Failed to fetch chat members');
        }
      } catch (error) {
        console.error('Error fetching chat members:', error);
      }
    };


    const initUser = async () => {
      const userId = tg.initDataUnsafe?.user?.id;
      setUser({
        username: tg.initDataUnsafe?.user?.username || "Username",
        firstName: tg.initDataUnsafe?.user?.first_name || "Pro",
        user_id: userId,
      });
    };

    initUser();
    fetchChatAvatar();
    fetchTasks();
    fetchChatMembers();

    const handleClickOutside = (event) => {
      if (
        taskFormRef.current &&
        !taskFormRef.current.contains(event.target) &&
        !event.target.closest(".no-close")
      ) {
        handleTaskFormClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      tg.BackButton.hide();
      tg.BackButton.offClick();
    };
  }, [apiUrl, chatId, navigate]);

  const handleTaskClick = (task) => {
    if (task && task.id) {
      navigate(`/EditTask/${task.id}`);
    } else {
      console.error("Task data is incomplete or missing.");
    }
  };

  const handleTaskInputToggle = (event) => {
    event.stopPropagation();
    setIsTaskInputVisible((prev) => !prev); 
  
    if (!isTaskInputVisible) {
      setTimeout(() => {
        if (taskInputRef.current) {
          taskInputRef.current.focus();
        }
      }, 0);
    }
  };

  const formatDeadline = (date) => {
    if (!date) return "No deadline";

    const today = new Date();
    const isCurrentYear = date.getFullYear() === today.getFullYear();
    const options = { day: 'numeric', month: 'long' };

    if (isCurrentYear) {
      return date.toLocaleDateString(undefined, options);
    } else {
      return date.toLocaleDateString(undefined, { ...options, year: 'numeric' });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleCreateTask();
    }
  };

  const handleTaskFormClose = () => {
    setIsTaskInputVisible(false);
    setTaskName("");
    setDeadline(null);
    setSelectedExecutors([]);
    setSelectedExecutorIds([]);
    setIsImportant(false);
  };

  const fetchExecutorAvatar = async (userId) => {
    if (executorAvatars[userId]) {
      return executorAvatars[userId];
    }
  
    try {
      const response = await fetch(`${apiUrl}/get-profile-photo?userId=${userId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        const avatarUrl = data.photoUrl || null;
        
        setExecutorAvatars((prevAvatars) => ({
          ...prevAvatars,
          [userId]: avatarUrl,
        }));
  
        return avatarUrl;
      } else {
        console.error('Failed to fetch executor avatar');
      }
    } catch (error) {
      console.error('Error fetching executor avatar:', error);
    }
    return null;
  };

  const handleCreateTask = async () => {
    const formattedDeadline = deadline ? new Date(deadline).toISOString().split('T')[0] : null;
  
    try {
      const response = await fetch(`${apiUrl}/create-task/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: taskName,
          deadline: formattedDeadline,
          chatId: chatId,
          executors: selectedExecutors, 
          executor_ids: selectedExecutorIds, 
          important: isImportant,
          author: user.username,
          user_id: user.user_id,
        }),
      });
  
      if (response.ok) {
        await fetchTasks();
        handleTaskFormClose();
      } else {
        console.error('Failed to create task');
      }
    } catch (error) {
      console.error('Error creating task:', error);
    }
  };
  

  const handleShowAllTasks = () => {
    setShowAllTasks(true);
  };

  const handleToggleCompletedTasks = () => {
    setShowCompletedTasks((prevState) => !prevState);  
  };

  const handleChatOpen = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-chat-invite-link?chatId=${chatId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.invite_link;
      } else {
        console.error('Failed to fetch chat invite link');
      }
    } catch (error) {
      console.error('Error fetching chat invite link:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const response = await fetch(`${apiUrl}/get-chat-tasks?chatId=${chatId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        const tasksWithAvatars = await Promise.all(
          data.tasks.map(async (task) => {
            const executorAvatars = await Promise.all(
              (task.executor_ids || []).map(async (executorId) => {
                const avatarUrl = await fetchExecutorAvatar(executorId);
                return { executorId, avatarUrl };
              })
            );
            return { ...task, executorAvatars };
          })
        );
        setTasks(tasksWithAvatars);
      } else {
        console.error('Failed to fetch tasks');
      }
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const handleTaskComplete = async (taskId) => {
    if (!taskId) {
      console.error("Task ID is required.");
      return;
    }

    const task = tasks.find((task) => task.id === taskId);
    if (!task) {
      console.error("Task not found.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/complete-task/${taskId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: !task.completed, 
        }),
      });

      if (response.ok) {
        await fetchTasks(); 
      } else {
        console.error("Failed to update task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const getFirstCharacter = (name) => {
    if (!name) return '?'; 
    return [...name][0];
  };

  const handleTaskComplete2 = async (taskId) => {
    if (!taskId) {
      console.error("Task ID is required.");
      return;
    }

    const task = tasks.find((task) => task.id === taskId);
    if (!task) {
      console.error("Task not found.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/complete-task2/${taskId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          completed: false, 
        }),
      });

      if (response.ok) {
        await fetchTasks(); 
      } else {
        console.error("Failed to update task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const activeTasks = tasks.filter(task => !task.completed && (task.author === user.username || (task.executors && task.executors.includes(user.username))));
  const filteredTasks = tasks.filter((task) => task && !task.completed);
  const completedTasks = tasks.filter(task => task && task.completed);
  const displayedTasks = filteredTasks;
  
  return (
    <div className="root">
      <div className="view">
        <div className="frame">
          <div className="header-chat">
            <div 
              className="avatar-wrapper" 
              onClick={handleChatOpen} 
              style={{ cursor: 'pointer' }}
            >
              {chatAvatar ? (
                <img className="logoAva" alt="Chat Avatar" src={chatAvatar} />
              ) : (
                <div className="avatar-placeholder">
                  {getFirstCharacter(chatName)}
                </div>
              )}
            </div>

            <div 
              className="text-wrapper" 
              onClick={handleChatOpen} 
              style={{ cursor: 'pointer' }}
            >
              <span className="chat-title">{chatName}</span>
              <span className="chat-members">{chatMembers.length} members</span>
            </div>
          </div>
        </div>
      </div>

      <div className="screen" ref={taskFormRef}>
        <div className="frame">
          <div
            className={`group ${isTaskInputVisible ? "input-active" : ""}`}
            onClick={handleTaskInputToggle}
          >
            {isTaskInputVisible ? (
              <div className="task-input-wrapper show-send-icon">
                <textarea
                  type="text"
                  className="task-input"
                  rows="1"
                  placeholder="Enter task name"
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  onKeyDown={handleKeyDown}  
                  ref={taskInputRef}
                  onClick={(e) => e.stopPropagation()}
                />
                <img
                  src="/icons/SendIcon.svg"
                  alt="Send"
                  className="filled-send-icon"
                  onClick={handleCreateTask}
                />
              </div>
            ) : (
              <>
                <img
                  src="/icons/PlusIcon.svg"
                  alt="Add Task"
                  className="filled-plus-icon"
                />
                <div className="tb">Add task</div>
              </>
            )}
          </div>
        </div>

        {isTaskInputVisible && (
          <div className="task-options">
            <div className="groups">
              <DatePicker
                selected={deadline}
                onChange={(date) => setDeadline(date)}
                locale="en-GB"
                customInput={<img src="/icons/CalendarActiveIcon.svg" />}
                className="icon-instance-node"
              />

              <div className="group-wrapper">
                <div className="pobep-tb-wrapper">
                  <div className="pobep-tb">{formatDeadline(deadline)}</div>
                </div>
              </div>
              
            </div>

            <div className="div">
              <img
                src="/icons/FolderIcon.svg"
                alt="Incoming"
                className="icon-instance-node"
              />
              <div className="group-wrapper">
                <div className="group-2">
                    <span className="task-input project-input">{chatName}</span>
                </div>
              </div>
            </div>
            <div className="div">
              <img
                src="/icons/Executor.svg" 
                alt="Executor"
                className="icon-instance-node"
              />
              <div className="group-wrapper">
                <div className="group-2">
                  <select 
                    multiple
                    className="task-input executor-input"
                    value={selectedExecutors}
                    onChange={(e) => {
                      const selectedOptions = Array.from(e.target.selectedOptions);
                      const usernames = selectedOptions.map(option => option.value);
                      const ids = selectedOptions.map(option => option.getAttribute('data-id'));
                      setSelectedExecutors(usernames);
                      setSelectedExecutorIds(ids);
                    }}
                  >
                    {chatMembers.map((member) => (
                      <option key={member.id} value={member.username} data-id={member.id}>
                        {member.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="div">
              <img
                src="/icons/ImportantIcon.svg"
                alt="Important Icon"
                className="icon-instance-node"
                style={{
                  width: "18px",
                  height: "18px",}}
              />
              <div className="group-wrapper">
                <label className="important-checkbox-label">
                  <input
                    type="checkbox"
                    checked={isImportant}
                    onChange={(e) => setIsImportant(e.target.checked)}
                  />
                  Important
                </label>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="view-2">
        <div className="groups-2 task-item">
          {displayedTasks.reverse().map((task) => (
              <div
                  key={task.id}
                  className="task-container"
                  onClick={() => handleTaskClick(task)}
                  >
              {task.important && <img src="/icons/ImportantIcon.svg" alt="Important" className="important-icon" style={{
                              width: "20px",
                              height: "20px",}} />}
              <Checkbox
                checked={task.completed}
                onClick={(e) => e.stopPropagation()} // Предотвращаем всплытие
                onChange={() => {
                  handleTaskComplete(task.id);
                }}
                inputProps={{ 'aria-label': 'controlled' }} 
              />
              <div className="task-content">
                <div className="task-title" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  
                  <span>{task.name}</span>
                  
                  <div className="executor-avatars">
                    {task.executor_ids && task.executor_ids.map((executorId, index) => {
                      const avatarUrl = executorAvatars[executorId];
                      return avatarUrl ? (
                        <img
                          key={executorId}
                          className="executor-avatar"
                          src={avatarUrl}
                          alt="Executor Avatar"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginLeft: "8px",
                          }}
                        />
                      ) : (
                        <div
                          key={executorId}
                          className="executor-placeholder"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#ccc",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "8px",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          {task.executors && task.executors[index] ? getFirstCharacter(task.executors[index]) : "?"}
                        </div>
                      );
                    })}
                  </div>
                </div>
                
                <div className="task-meta">
                  {task.deadline && (
                    <div className="task-deadline">
                      <img
                        src="/img/RedFlag.svg"
                        alt="RedFlag"
                        className="red-flag"
                      />
                      <span className="task-date">
                        {formatDeadline(new Date(task.deadline))}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

        {completedTasks.length > 0 ? (
          <div className="all-tasks" onClick={handleToggleCompletedTasks}>
            <img
              className="group-3"
              alt="Archive Icon"
              src="/icons/Completed.svg"
            />
            <div className="alltasks">Completed Tasks</div>
            <div className="count">{completedTasks.length}</div>
            <img
              className={`group-4 ${showCompletedTasks ? "arrow-up" : "arrow-down"}`}
              alt="Arrow"
              src="/icons/ArrowRight.svg"
            />
          </div>
        ) : (filteredTasks.length === 0 && completedTasks.length === 0 && (
          <span className="no-text">Please add new task</span>
        ))}

          {showCompletedTasks && (
            <div className="completed-tasks no-close">
              {completedTasks.map((task) => (
                <div
                key={task.id}
                className="task-container completed"
                onClick={() => handleTaskClick(task)}
                >
                  <Checkbox
                    checked={task.completed}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                      handleTaskComplete2(task.id);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }} 
                  />
                  <div className="task-content">
                    <div className="task-title">{task.name}</div>
                    <div className="task-meta">
                      {task.deadline && <span className="task-date">{formatDeadline(new Date(task.deadline))} • </span>}
                      {chatName}
                    </div>
                    <div className="executor-avatars">
                    {task.executor_ids && task.executor_ids.map((executorId, index) => {
                      const avatarUrl = executorAvatars[executorId];
                      return avatarUrl ? (
                        <img
                          key={executorId}
                          className="executor-avatar"
                          src={avatarUrl}
                          alt="Executor Avatar"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginLeft: "8px",
                          }}
                        />
                      ) : (
                        <div
                          key={executorId}
                          className="executor-placeholder"
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            backgroundColor: "#ccc",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "8px",
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          {task.executors && task.executors[index] ? getFirstCharacter(task.executors[index]) : "?"}
                        </div>
                      );
                    })}
                  </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          
        </div>
      </div>

    </div>
  );
};

export default Chats;
